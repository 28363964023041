import * as React from 'react';
import Button from '../components/Button';
import logoSource from '../images/logo.svg';
import user from '../images/people/till-carlos.png';
import Seo from '../components/SEO';

const SignUpPage = () => {
  return (
    <>
      <Seo title='Signup' />
      <main>
        <div className='flex  flex-col-reverse md:flex-row h-auto md:h-screen'>
          <section className='w-full md:w-2/3 flex p-8 flex-col items-center justify-between bg-gradient-to-br from-gray-200 dark:from-primary to-backgrd dark:to-dark_bg-normal'>
            <div className='flex items-center m-4 '>
              <img src={logoSource} className='w-10' alt='Pairing.dev logo' />
              <span className='font-bold text-xl mx-2'>Pairing.dev</span>
            </div>

            <div className='w-2/3 flex flex-col items-center'>
              <p className='text-center'>
                Thanks for your interest. I'll review your submission, respond
                personally, and then connect you with the closest PM so you can
                see how we work.
              </p>
              <img src={user} className='w-14 h-14 my-4' alt='' srcset='' />
              <b className='text-xl'>Dipl.-Inf. Till Carlos</b>
              <span className='text-gray-500 text-sm'>FOUNDER</span>
            </div>

            <div></div>
          </section>
          <section className='w-full p-4 flex flex-col justify-center items-center'>
            <div className='flex md:hidden items-center m-4 '>
              <img src={logoSource} className='w-10' alt='Pairing.dev logo' />
              <span className='font-bold text-xl mx-2'>Pairing.dev</span>
            </div>
            <form
              className='flex flex-col'
              method='post'
              action='https://getform.io/f/9f8ad2af-d9df-42b6-9906-0abeae9d01c7'
            >
              <label>
                <b>Name</b>
                <input
                  className='form-input'
                  type='text'
                  name='name'
                  id='name'
                  required
                />
              </label>
              <label>
                <b>Email/Phone/Messenger</b>
                <input
                  className='form-input'
                  type='text'
                  name='email'
                  id='email'
                  required
                />
              </label>
              <label className='text-lg flex flex-col'>
                <b>What's your situation?</b>
                <div className='my-1'>
                  <input
                    className='mr-2 leading-tight text-lg'
                    name='I’m a PM/PO and I’d like to know more about your teams'
                    type='checkbox'
                  />
                  <span className='text-base'>
                    I’m a PM/PO and I’d like to know more about your teams
                  </span>
                </div>
                <div className='my-1'>
                  <input
                    className='mr-2 leading-tight text-lg'
                    name='I’m a business owner / controller and I’d like to talk about
                    cost / KPIs'
                    type='checkbox'
                  />
                  <span className='text-base'>
                    I’m a business owner / controller and I’d like to talk about
                    cost / KPIs
                  </span>
                </div>
                <div className='my-1'>
                  <input
                    className='mr-2 leading-tight text-lg'
                    name='I’m a developer. This looks like a cool place to work. How
                    can I join?'
                    type='checkbox'
                  />
                  <span className='text-base'>
                    I’m a developer. This looks like a cool place to work. How
                    can I join?
                  </span>
                </div>
                <div className='my-1'>
                  <input
                    className='mr-2 leading-tight text-lg'
                    name='Other'
                    type='checkbox'
                  />
                  <span className='text-base'>Other (specify below)</span>
                </div>
              </label>
              <label>
                <b>Anything else we should know?</b>
                <textarea
                  className='form-input'
                  name='message'
                  id='message'
                  rows='5'
                  required
                />
              </label>
              <Button className='float-right' type='submit'>
                Put me in touch!
              </Button>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};

export default SignUpPage;
